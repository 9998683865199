/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).load(function() {
          var menue_position = 1;
          var screen = $(window).width();

          if (screen > 768) {
            var scroll = $(window).scrollTop();

            if(scroll >= menue_position) {
              $('.header-widgets').addClass('header-widgets-small');
              $('.jumbotron').addClass('jumbotron-small');
              $('.main-navigation').addClass('sticky');
            } else {
              $('.header-widgets').removeClass('header-widgets-small');
              $('.jumbotron').removeClass('jumbotron-small');
              $('.main-navigation').removeClass('sticky');
            }
          }

        // Mobile scripts
        if (Modernizr.touch) {
          $( '#nav-primary li:has(ul)' ).doubleTapToGo();
        }

          // Weiterlesen-Link
          var read_more_link = '<a href="#" class="read-more">&raquo; weiterlesen...</a>';

          $('.fl-col-content').each(function() {
            if ($(this).html().search('<!--more-->') !== -1) {
              var textparts = $(this).html().split('<!--more-->');

              $(this).html(textparts[0] + read_more_link +'<div style="display:none">' + textparts[1] + '</div>');
            }
          });

          $('.read-more').click(function() {
            $(this).hide();
            $(this).parent().next('div:first').fadeIn();

            return false;
          });

          /*
          var sliderHeight = ($('.slideshow').height());
          if (screen > 768) {
            $('.slider-overlay').css('height', sliderHeight);
          } else {
            $('.slider-overlay').css('height', sliderHeight + 71);
          }
          */

          $(window).scroll(function() {
            if (screen > 768) {
              var scroll = $(window).scrollTop();

              if(scroll >= menue_position) {
                $('.header-widgets').addClass('header-widgets-small');
                $('.jumbotron').addClass('jumbotron-small');
                $('.main-navigation').addClass('sticky');
              } else {
                $('.header-widgets').removeClass('header-widgets-small');
                $('.jumbotron').removeClass('jumbotron-small');
                $('.main-navigation').removeClass('sticky');
              }
            }
          });
        });

        $('.testimonial-bubble').slick({
            slidesToShow: 1,
            arrows: true,
            dots: true,
            autoplay: false,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            prevArrow: '<i class="fa fa-arrow-left prev" aria-hidden="true"></i>',
            nextArrow: '<i class="fa fa-arrow-right next" aria-hidden="true"></i>'
        });

        // Smooth Scrolling Script by @chriscoyier
        //$('a[href*="#"]:not([href="#"])').click(function () {
            //if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                //var target = $(this.hash);
                //target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
               // if (target.length) {
                //  if(!$(".jumbotron").hasClass("jumbotron-small")) {
                //    $('.header-widgets').addClass('header-widgets-small');
                //    $('.jumbotron').addClass('jumbotron-small');
                //  }

               //   $('html,body').animate({
                //      scrollTop: (target.offset().top - 125)
               //   }, 1000);

               //     return false;
               // }
          //  }
       // });


        // Scroll to Top Button fading function
        // var sTb = $('.scrollTop');
        // $(sTb).hide();
        //
        // $(document).scroll(function () {
        //     var y = $(this).scrollTop();
        //     if (y > 300) {
        //         $(sTb).fadeIn();
        //     } else {
        //         $(sTb).fadeOut();
        //     }
        // });

        // Manual-generated WordPress Navi WITH First-Level Landingpage

            var screen = $(window).width();
            if (screen < 768) {
                //Add Toggle Icons to Nav for Dropdown Touch Device Support
                var parentItem = $('.right-off-canvas-menu .menu-item-has-children');
                     $(parentItem).each(function(){
                         if(!$(this).hasClass('spalte')){
                             $(this).children('a').after('<div class="toggle"><span class="glyphicon glyphicon-chevron-down"></span></div>');
                         }
                     });

                $('.right-off-canvas-menu .current-menu-ancestor').addClass('open');
                $('.right-off-canvas-menu .current-menu-parent').addClass('open');
                $('.right-off-canvas-menu .menu-item-has-children.current-menu-item').addClass('open');
            }
            // Prevents the Submenus from stayin' opened after page reloads like it does on phones
            $('.right-off-canvas-menu .menu-item-has-children > .toggle').on('click', function () {
                var element = $(this).parent('li');
                if (element.hasClass('open')) {
                    element.removeClass('open');
                }
                else {
                    element.addClass('open');
                }
            });


        // Scroll to Top Button fading function
        $(document).scroll(function () {
            var vpHeight = $(window).height();
            var y = $(this).scrollTop();
            if (y > vpHeight/2) {
                $('.to-top').removeClass('hidden');
                $('.floaty-wrap').removeClass('hidden');
            } else {
                $('.to-top').addClass('hidden');
                $('.floaty-wrap').addClass('hidden');
            }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var viewport = $(window).width();
        // Only make the nav sticky on tablet and up
        if(viewport > 768){
            // Define Vars for affix calcs
            var headerHeight = ($('.affix-top .colored-header').height());
            $('.jumbotron').css('padding-top',headerHeight);
            /*var nav = $('.main-navigation');
            var navHeight = $(nav).height();
            var offsetAffix = $(nav).offset();
            // Makes the nav sticky
            $(nav).affix({
                offset: {
                  top: offsetAffix.top // You may have to add some extra px for borders etc.
                }
            });
            // Add padding to body to prevent lil' page-jump when nav is stickfied
            $(nav).on('affix.bs.affix', function () {
                 $('body').css('padding-top', navHeight + 'px');
            });
            // Remove the x-tra padding after nav gets static again
            $(nav).on('affixed-top.bs.affix', function () {
                 $('body').css('padding-top', '0');
            });*/


            // Initialize Tooltips on specific elements (uses title)
            // $('.tooltip-info a').tooltip({placement: 'bottom'});
        }


        // Toggle function for offcanvas navigation
        $( "#nav-toggle, .exit-off-canvas" ).click(function() {
            $( ".off-canvas-wrap" ).toggleClass( "move-left" );
        });

        // Recalculate offset for sticky element and position for mega dropdown centering on resize
        $( window ).resize(function() {
            var headerHeight = ($('.affix-top .colored-header').height());
            $('.jumbotron').css('padding-top',headerHeight);
            console.log(headerHeight);
            //var offsetAffix = $('.main-navigation').offset();
            // $('.ubermenu-submenu-type-mega').center();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
